import React, { useState } from 'react';
import './Popup.css';

const Popup = ({ position, content, onClose }) => {
  const [selectedSection, setSelectedSection] = useState('general');

  if (!position || !content) return null;

  const renderHTML = (htmlContent) => {
    return { __html: htmlContent };
  };

  const handleCloseClick = (event) => {
    event.stopPropagation(); 
    event.preventDefault();
    onClose();
  };

  const handleSectionChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedSection(event.target.value);
  };

  const getContent = () => {
    switch (selectedSection) {
      case 'general':
        return Object.entries(content).map(([key, value]) => (
          key !== 'Name' && (
            <div key={key} className="popup-field">
              <strong>{key}:</strong>
              {key === 'description' ? (
                <div dangerouslySetInnerHTML={renderHTML(value)} />
              ) : (
                <span>{value}</span>
              )}
            </div>
          )
        ));
      case 'bim':
        return (
          <>
            <div className="popup-field">
              <strong>Building Type:</strong>
              <span>Commercial</span>
            </div>
            <div className="popup-field">
              <strong>Construction Year:</strong>
              <span>2010</span>
            </div>
            <div className="popup-field">
              <strong>Architect:</strong>
              <span>Zaha Hadid Architects</span>
            </div>
            <div className="popup-field">
              <strong>Materials Used:</strong>
              <span>Concrete, Glass, Steel</span>
            </div>
            <div className="popup-field">
              <strong>Structural Integrity Score:</strong>
              <span>85/100</span>
            </div>
            <div className="popup-field">
              <strong>HVAC System:</strong>
              <span>Centralized HVAC, Energy Efficiency Rating: A+</span>
            </div>
          </>
        );
      case 'geoai':
        return (
          <>
            <div className="popup-field">
              <strong>AI Analysis Confidence:</strong>
              <span>92%</span>
            </div>
            <div className="popup-field">
              <strong>Predicted Land Use:</strong>
              <span>Mixed-use, Commercial</span>
            </div>
            <div className="popup-field">
              <strong>Environmental Impact Score:</strong>
              <span>Low, 2.5/10</span>
            </div>
            <div className="popup-field">
              <strong>Maintenance Prediction:</strong>
              <span>Roof Maintenance Required in 6 months</span>
            </div>
            <div className="popup-field">
              <strong>Heatmap Intensity:</strong>
              <span>High - 75%</span>
            </div>
            <div className="popup-field">
              <strong>Urban Growth Prediction:</strong>
              <span>High growth potential over the next 5 years</span>
            </div>
          </>
        );
      case 'transport':
        return (
          <div className="transport-analysis">
            <button className="transport-button">Nearest Bus Stop</button>
            <button className="transport-button">Nearest MRT Station</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="popup"
      style={{ top: position.y, left: position.x }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className="popup-header">
        <select onChange={handleSectionChange} value={selectedSection}>
          <option value="general">General Information</option>
          <option value="bim">BIM Integration</option>
          <option value="geoai">Geo AI/ML Analysis</option>
          <option value="transport">Transport Analysis</option>
        </select>
        <button className="popup-close" onClick={handleCloseClick}>×</button>
      </div>
      <div className="popup-content">
        <div className="popup-body">
          {getContent()}
        </div>
      </div>
    </div>
  );
};

export default Popup;
