import React, { useState } from 'react';
import './Panel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad, faSatellite } from '@fortawesome/free-solid-svg-icons';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const LayerListPanel = ({ layers }) => {
  const map = useMap();
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [selectedBaseLayer, setSelectedBaseLayer] = useState(null);

  const toggleAccordion = (accordion) => {
    setActiveAccordion(activeAccordion === accordion ? null : accordion);
  };

  const switchLayer = (layerType) => {
    map.eachLayer((layer) => {
      if (layer.options && layer.options.id === 'base-layer') {
        map.removeLayer(layer);
      }
    });

    if (layerType) {
      let layer;
      switch (layerType) {
        case 'streets':
          layer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            id: 'base-layer',
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          });
          break;
        case 'satellite':
          layer = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
            id: 'base-layer',
            attribution: '&copy; <a href="https://opentopomap.org/">OpenTopoMap</a> contributors'
          });
          break;
        default:
          break;
      }
      if (layer) {
        map.addLayer(layer);
      }
    }

    setSelectedBaseLayer(layerType);
  };

  const zoomToFirstBuilding = () => {
    const firstBuildingCoordinates = [1.3521, 103.8198]; // Replace with actual coordinates of the first building
    map.setView(firstBuildingCoordinates, 18); // Zoom level 18
  };

  return (
    <div className="panel open">
      <h2 className="panel-title">Layer List</h2>
      <div className="accordion">
        <div className="accordion-item">
          <div
            className="accordion-title"
            onClick={() => toggleAccordion('basemap')}
          >
            <h3>Basemap</h3>
          </div>
          {activeAccordion === 'basemap' && (
            <div className="accordion-content">
              <label className="radio-container">
                <input
                  type="radio"
                  name="basemap"
                  checked={selectedBaseLayer === 'streets'}
                  onChange={() => switchLayer('streets')}
                />
                <FontAwesomeIcon icon={faRoad} className="fa-icon" />
                <span>Streets</span>
              </label>
              <label className="radio-container">
                <input
                  type="radio"
                  name="basemap"
                  checked={selectedBaseLayer === 'satellite'}
                  onChange={() => switchLayer('satellite')}
                />
                <FontAwesomeIcon icon={faSatellite} className="fa-icon" />
                <span>Satellite</span>
              </label>
              <label className="radio-container">
                <input
                  type="radio"
                  name="basemap"
                  checked={selectedBaseLayer === null}
                  onChange={() => switchLayer(null)}
                />
                <span>None</span>
              </label>
            </div>
          )}
        </div>
        <div className="accordion-item">
          <div
            className="accordion-title"
            onClick={() => toggleAccordion('additionalLayers')}
          >
            <h3>Additional Layers</h3>
          </div>
          {activeAccordion === 'additionalLayers' && (
            <div className="accordion-content">
              {layers.map((layer, index) => (
                <div key={index} className="layer-item" onClick={zoomToFirstBuilding}>
                  <div>{layer.name}</div>
                  <div className="layer-legend" style={{ backgroundColor: layer.color }}></div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LayerListPanel;
