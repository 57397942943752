import React, { useState } from 'react';
import { useMap } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import L from 'leaflet';

const SearchComponent = () => {
  const map = useMap();
  const [query, setQuery] = useState('');
  const [marker, setMarker] = useState(null);

  const customIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
  });

  const handleSearch = async (e) => {
    e.preventDefault();
    const provider = new OpenStreetMapProvider();
    const results = await provider.search({ query });

    const singaporeBounds = {
      latMin: 1.130475,
      latMax: 1.450475,
      lonMin: 103.620145,
      lonMax: 104.020145,
    };

    const singaporeResults = results.filter(result => {
      const { x, y } = result;
      return y >= singaporeBounds.latMin && y <= singaporeBounds.latMax &&
             x >= singaporeBounds.lonMin && x <= singaporeBounds.lonMax;
    });

    if (singaporeResults && singaporeResults.length > 0) {
      const { x, y, label } = singaporeResults[0];
      map.setView([y, x], 16);

      if (marker) {
        map.removeLayer(marker);
      }

      const newMarker = L.marker([y, x], { icon: customIcon }).addTo(map).bindPopup(label);
      setMarker(newMarker);
    } else {
      alert('No results found within Singapore');
    }
  };

  return (
    <div className="search-container">
      <form onSubmit={handleSearch}>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search location..."
        />
        <button type="submit">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M10 2a8 8 0 1 0 5.29 14.29l4.48 4.48a1 1 0 0 0 1.42-1.42l-4.48-4.48A8 8 0 0 0 10 2zm0 2a6 6 0 1 1 0 12 6 6 0 0 1 0-12z"/>
          </svg>
        </button>
      </form>
    </div>
  );
};

export default SearchComponent;
