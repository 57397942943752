import React, { useState } from 'react';
import { useMap } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import L from 'leaflet';
import 'leaflet-routing-machine';

const FindDirectionPanel = () => {
  const map = useMap();
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [routingControl, setRoutingControl] = useState(null);

  const customIcon = (color) => L.icon({
    iconUrl: `https://cdn.jsdelivr.net/npm/leaflet@1.7.1/dist/images/marker-icon-2x.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
    className: `custom-marker custom-marker-${color}`,
  });

  const handleFindDirection = async () => {
    const provider = new OpenStreetMapProvider();

    const startResults = await provider.search({ query: start + ', Singapore' });
    const endResults = await provider.search({ query: end + ', Singapore' });

    if (startResults.length === 0 || endResults.length === 0) {
      alert('Invalid postal code(s)');
      return;
    }

    const startCoords = startResults[0];
    const endCoords = endResults[0];

    if (routingControl) {
      map.removeControl(routingControl);
    }

    const newRoutingControl = L.Routing.control({
      waypoints: [
        L.latLng(startCoords.y, startCoords.x),
        L.latLng(endCoords.y, endCoords.x),
      ],
      routeWhileDragging: true,
      createMarker: function (i, waypoint, n) {
        let markerOptions = {};
        if (i === 0) {
          markerOptions.icon = customIcon('green');
        } else if (i === n - 1) {
          markerOptions.icon = customIcon('red');
        }
        return L.marker(waypoint.latLng, markerOptions);
      },
      lineOptions: {
        styles: [{ color: '#8B0000', weight: 4, opacity: 0.7, dashArray: '5, 5' }],
      },
    }).addTo(map);

    setRoutingControl(newRoutingControl);
  };

  const handleClearDirections = () => {
    if (routingControl) {
      map.removeControl(routingControl);
    }
    setRoutingControl(null);
    setStart('');
    setEnd('');
  };

  return (
    <div className="panel">
      <h2 className="panel-title">Find Direction</h2>
      <div className="input-group">
        <label>Start Postal Code:</label>
        <input
          type="text"
          value={start}
          onChange={(e) => setStart(e.target.value)}
          placeholder="Start Postal Code"
        />
      </div>
      <div className="input-group">
        <label>End Postal Code:</label>
        <input
          type="text"
          value={end}
          onChange={(e) => setEnd(e.target.value)}
          placeholder="End Postal Code"
        />
      </div>
      <button onClick={handleFindDirection}>Find Direction</button>
      <button onClick={handleClearDirections} className="clear-button">Clear</button>
    </div>
  );
};

export default FindDirectionPanel;
