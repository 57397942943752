import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FaSearchPlus, FaSearchMinus, FaExpand, FaSyncAlt, FaHandPaper, FaInfoCircle, FaCommentDots } from 'react-icons/fa';
import Popup from './Popup';
import Chatbot from './Chatbot'; // Import the Chatbot component
import './ThreeDGeospatialApp.css';

const ThreeDGeospatialApp = () => {
  const cameraRef = useRef();
  const controlsRef = useRef();
  const sceneRef = useRef();
  const rendererRef = useRef();
  const buildingMeshesRef = useRef([]);
  const roadMeshesRef = useRef([]);
  const openSpaceMeshesRef = useRef([]);
  const geojsonDataRef = useRef();
  const loadedBuildingsRef = useRef(0);

  const [popupContent, setPopupContent] = useState(null);
  const [popupPosition, setPopupPosition] = useState(null);
  const [clickEnabled, setClickEnabled] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false); // State for chatbot toggle

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };

  const zoomIn = () => {
    const camera = cameraRef.current;
    const controls = controlsRef.current;
    if (camera && controls) {
      camera.position.z -= camera.position.z * 0.1; // Zoom in
      controls.update();
    }
  };

  const zoomOut = () => {
    const camera = cameraRef.current;
    const controls = controlsRef.current;
    if (camera && controls) {
      camera.position.z += camera.position.z * 0.1; // Zoom out
      controls.update();
    }
  };

  const setZoomExtent = () => {
    const camera = cameraRef.current;
    const controls = controlsRef.current;
    if (camera && controls) {
      fitCameraToObject(camera, buildingMeshesRef.current, controls, 1.4);
    }
  };

  const setPanMode = () => {
    const controls = controlsRef.current;
    if (controls) {
      controls.enablePan = true;
      controls.enableRotate = false;
    }
  };

  const setRotateMode = () => {
    const controls = controlsRef.current;
    if (controls) {
      controls.enablePan = false;
      controls.enableRotate = true;
    }
  };

  const toggleClickHandler = () => {
    setClickEnabled(!clickEnabled);
  };

  const getIntersects = (x, y) => {
    const camera = cameraRef.current;
    const mouse = new THREE.Vector2();
    mouse.x = (x / window.innerWidth) * 2 - 1;
    mouse.y = -(y / window.innerHeight) * 2 + 1;

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);

    return raycaster.intersectObjects([
      ...buildingMeshesRef.current,
      ...openSpaceMeshesRef.current,
      ...roadMeshesRef.current
    ], true);
  };

  const onObjectClick = (event) => {
    if (!clickEnabled) return; // Prevent click action if disabled

    const intersects = getIntersects(event.clientX, event.clientY);
    if (intersects.length > 0) {
      const intersected = intersects[0];
      const featureData = intersected.object.userData;
      setPopupContent(featureData);
      setPopupPosition({ x: event.clientX, y: event.clientY });
    }
  };

  const fitCameraToObject = (camera, objects, controls, buffer = 2.0) => {
    const box = new THREE.Box3();
    objects.forEach((object) => box.expandByObject(object));
    const size = new THREE.Vector3();
    box.getSize(size);

    const center = new THREE.Vector3();
    box.getCenter(center);

    size.multiplyScalar(buffer);

    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = camera.fov * (Math.PI / 180);
    let cameraZ = Math.abs((maxDim / 2) * Math.tan(fov * 2));
    cameraZ *= 4;

    camera.position.set(center.x, center.y - cameraZ / 2, cameraZ);
    camera.lookAt(center);

    controls.maxDistance = cameraZ * 6;
    controls.target.copy(center);

    camera.updateProjectionMatrix();
  };

  const animate = () => {
    const controls = controlsRef.current;
    const renderer = rendererRef.current;
    const scene = sceneRef.current;
    const camera = cameraRef.current;

    requestAnimationFrame(animate);
    if (controls) controls.update();
    if (renderer && scene && camera) {
      renderer.render(scene, camera);
    }
  };

  useEffect(() => {
    // Initialize the scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.01, 100000);
    const renderer = new THREE.WebGLRenderer({ antialias: true, precision: 'highp' });

    cameraRef.current = camera;
    sceneRef.current = scene;
    rendererRef.current = renderer;

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0xffffff); // Set background color to white
    document.getElementById('three-canvas').appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controlsRef.current = controls;

    const ambientLight = new THREE.AmbientLight(0x404040); // Soft white light
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0, 1, 1).normalize();
    scene.add(directionalLight);

    const scaleBarElement = document.getElementById('scale-bar');

    function transformCoords(coords) {
      if (!Array.isArray(coords) || coords.length < 2 || typeof coords[0] !== 'number' || typeof coords[1] !== 'number') {
        console.error('Invalid coordinates array:', coords);
        return null;
      }

      const [lon, lat] = coords;

      const scale = 1000;
      const x = lon * scale;
      const y = lat * scale;

      return isNaN(x) || isNaN(y) ? null : { x, y };
    }

    function updateScaleBar() {
      const screenDistance = 100;
      const p1 = new THREE.Vector3(-screenDistance / window.innerWidth, 0, 0.5).unproject(camera);
      const p2 = new THREE.Vector3(screenDistance / window.innerWidth, 0, 0.5).unproject(camera);
      const worldDistance = p1.distanceTo(p2);

      let scaleText;
      if (worldDistance > 1000) {
        scaleText = `Scale: ${(worldDistance / 1000).toFixed(2)} km`;
      } else {
        scaleText = `Scale: ${worldDistance.toFixed(2)} m`;
      }

      scaleBarElement.textContent = scaleText;
    }

    function addBuildingsFromGeoJSON(geojson) {
      geojsonDataRef.current = geojson; // Store GeoJSON data for later use
      loadFirstBuilding(); // Start loading the first building
    }

    function loadFirstBuilding() {
      const firstBuilding = geojsonDataRef.current.features.find((feature) => feature.geometry.type === 'Polygon'); // Get the first building only
      if (firstBuilding) {
        loadBuilding(firstBuilding);
        fitCameraToObject(camera, buildingMeshesRef.current, controls, 1.4); // Set zoom extent to the first building
        setTimeout(loadNextBatch, 200); // Increase delay to allow the first building to render
      }
    }

    function loadBuilding(feature) {
      const geometryType = feature.geometry.type;
      const coords = feature.geometry.coordinates;

      if (geometryType === 'Polygon') {
        const shape = new THREE.Shape();
        const transformedCoords = coords[0].map(transformCoords);

        // Filter out any null or invalid coordinates
        const validCoords = transformedCoords.filter((coord) => coord !== null);

        if (validCoords.length < 3) {
          console.error('Invalid polygon: less than 3 valid points', validCoords);
          return;
        }

        shape.moveTo(validCoords[0].x, validCoords[0].y);
        validCoords.slice(1).forEach((coord) => shape.lineTo(coord.x, coord.y));
        shape.lineTo(validCoords[0].x, validCoords[0].y);

        const area = calculatePolygonArea(validCoords);
        const height = (Math.sqrt(area) * 4) / 2;

        const extrudeSettings = {
          depth: height,
          bevelEnabled: false,
        };
        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        const material = new THREE.MeshStandardMaterial({ color: 0xff0000 });

        const building = new THREE.Mesh(geometry, material);
        building.userData = feature.properties;
        building.userData.type = 'Building';
        scene.add(building);
        buildingMeshesRef.current.push(building);

        const edges = new THREE.EdgesGeometry(geometry);
        const lineMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });
        const lines = new THREE.LineSegments(edges, lineMaterial);
        scene.add(lines);
      }
    }

    function calculatePolygonArea(coords) {
      let area = 0;
      const n = coords.length;
      for (let i = 0; i < n; i++) {
        const x1 = coords[i].x;
        const y1 = coords[i].y;
        const x2 = coords[(i + 1) % n].x;
        const y2 = coords[(i + 1) % n].y;
        area += x1 * y2 - y1 * x2;
      }
      return Math.abs(area / 2);
    }

    function loadNextBatch() {
      const batchSize = 3; // Reduce number of buildings to load per frame to avoid overloading
      const features = geojsonDataRef.current.features;

      let batch = 0;
      while (batch < batchSize && loadedBuildingsRef.current < features.length) {
        const feature = features[loadedBuildingsRef.current];
        loadedBuildingsRef.current++;
        batch++;

        if (feature.geometry.type === 'Polygon') {
          loadBuilding(feature);
        }
      }

      // Schedule the next batch
      if (loadedBuildingsRef.current < features.length) {
        setTimeout(loadNextBatch, 200); // Increase delay to avoid blocking
      }
    }

    function addOpenSpacesFromGeoJSON(geojson) {
      geojson.features.forEach((feature) => {
        if (feature.geometry.type === 'Polygon') {
          loadOpenSpace(feature);
        }
      });
    }

    function loadOpenSpace(feature) {
      const geometryType = feature.geometry.type;
      const coords = feature.geometry.coordinates;

      if (geometryType === 'Polygon') {
        const shape = new THREE.Shape();
        const transformedCoords = coords[0].map(transformCoords);

        const validCoords = transformedCoords.filter((coord) => coord !== null);

        if (validCoords.length < 3) {
          console.error('Invalid polygon: less than 3 valid points', validCoords);
          return;
        }

        shape.moveTo(validCoords[0].x, validCoords[0].y);
        validCoords.slice(1).forEach((coord) => shape.lineTo(coord.x, coord.y));
        shape.lineTo(validCoords[0].x, validCoords[0].y);

        const geometry = new THREE.ShapeGeometry(shape);
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide });

        const openSpace = new THREE.Mesh(geometry, material);
        openSpace.userData = feature.properties;
        openSpace.userData.type = 'Open Space';
        scene.add(openSpace);
        openSpaceMeshesRef.current.push(openSpace);
      }
    }

    function loadRoadNetwork(roadData) {
      roadData.features.forEach(loadRoad);
    }

    function loadRoad(feature) {
      const coords = feature.geometry.coordinates;

      const firstCoord = Array.isArray(coords[0]) ? coords[0] : coords;

      const transformedCoords = firstCoord.map(transformCoords).filter((coord) => coord !== null);

      if (transformedCoords.length < 2) {
        console.error('Invalid road: less than 2 valid points', transformedCoords);
        return;
      }

      const geometry = new THREE.BufferGeometry().setFromPoints(transformedCoords);
      const material = new THREE.LineBasicMaterial({ color: 0x000000 });
      const road = new THREE.Line(geometry, material);
      road.userData = feature.properties;
      road.userData.type = 'Road';
      scene.add(road);
      roadMeshesRef.current.push(road);
    }

    fetch('/geojson/data.geojson')
      .then((response) => response.json())
      .then((data) => {
        addBuildingsFromGeoJSON(data);
      })
      .catch((error) => console.error('Error loading GeoJSON:', error));

    fetch('/geojson/roaddata.geojson')
      .then((response) => response.json())
      .then((data) => {
        loadRoadNetwork(data);
      })
      .catch((error) => console.error('Error loading road GeoJSON:', error));

    fetch('/geojson/OpenSpace.geojson')
      .then((response) => response.json())
      .then((data) => {
        addOpenSpacesFromGeoJSON(data);
      })
      .catch((error) => console.error('Error loading open space GeoJSON:', error));

    animate();

    window.addEventListener('resize', () => {
      const camera = cameraRef.current;
      const renderer = rendererRef.current;

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    });

    // Add the click event listener
    if (clickEnabled) {
      window.addEventListener('click', onObjectClick);
    }

    return () => {
      const renderer = rendererRef.current;
      document.getElementById('three-canvas').removeChild(renderer.domElement);
      window.removeEventListener('resize', () => {});
      window.removeEventListener('click', onObjectClick);
    };
  }, []);

  // Handle enabling/disabling click functionality separately
  useEffect(() => {
    if (clickEnabled) {
      window.addEventListener('click', onObjectClick);
    } else {
      window.removeEventListener('click', onObjectClick);
    }

    // Clean up the listener on unmount or when clickEnabled changes
    return () => {
      window.removeEventListener('click', onObjectClick);
    };
  }, [clickEnabled]);

  return (
    <div>
      <div id="three-canvas"></div>
      <Popup
        position={popupPosition}
        content={popupContent}
        onClose={() => setPopupPosition(null)}
      />
      <div className="toolbar">
        <button onClick={setZoomExtent} title="Zoom Extent">
          <FaExpand />
        </button>
        <button onClick={zoomIn} title="Zoom In">
          <FaSearchPlus />
        </button>
        <button onClick={zoomOut} title="Zoom Out">
          <FaSearchMinus />
        </button>
        <button onClick={setRotateMode} title="3D Rotation">
          <FaSyncAlt />
        </button>
        <button onClick={setPanMode} title="Pan">
          <FaHandPaper />
        </button>
        <button
          onClick={toggleClickHandler}
          className={clickEnabled ? 'active' : ''}
          title="Enable/Disable Info Click"
        >
          <FaInfoCircle />
        </button>
        <button onClick={toggleChatbot} title="Toggle Chatbot">
          <FaCommentDots />
        </button>
      </div>
      <Chatbot isOpen={isChatbotOpen} onClose={toggleChatbot} />
      <div id="scale-bar" className="scale-bar">
        Scale: 1 km
      </div>
    </div>
  );
};

export default ThreeDGeospatialApp;
