import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {
  const navigate = useNavigate();

  const handleGeospatialClick = () => {
    navigate('/geospatial');
  };

  const handle3DGeospatialClick = () => {
    navigate('/3dgeospatial'); // Navigate to 3D Geospatial page
  };

  return (
    <div className="home-page">
      <h1>Welcome to Geospatial AI</h1>
      <div className="components-container">
        <div className="component" onClick={handleGeospatialClick}>
          <h2>2D Geospatial - Leaflet</h2>
          <h3>Routing</h3>
          <h3>Gen AI Samples</h3>
          <p>Click to enter the Geospatial 2D app.</p>
        </div>

        <div className="component" onClick={handle3DGeospatialClick}>
          <h2>3D Geospatial - WebGL</h2>
          <h3>Routing</h3>
          <h3>Gen AI Samples</h3>
          <p>Click to enter the Geospatial 3D app.</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
