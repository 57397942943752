import React, { useState } from 'react';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faRoute, faCubes, faFileUpload } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ onPanelOpen }) => {
  const [activePanel, setActivePanel] = useState(null);

  const togglePanel = (panel) => {
    const newPanel = activePanel === panel ? null : panel;
    setActivePanel(newPanel);
    onPanelOpen(newPanel);
  };

  return (
    <div className="sidebar">
      <div className={`sidebar-btn ${activePanel === 'layerList' ? 'active' : ''}`} onClick={() => togglePanel('layerList')}>
        <FontAwesomeIcon icon={faLayerGroup} className="fa-icon" />
      </div>
      <div className={`sidebar-btn ${activePanel === 'findDirection' ? 'active' : ''}`} onClick={() => togglePanel('findDirection')}>
        <FontAwesomeIcon icon={faRoute} className="fa-icon" />
      </div>
      <div className={`sidebar-btn ${activePanel === 'add3DData' ? 'active' : ''}`} onClick={() => togglePanel('add3DData')}>
        <FontAwesomeIcon icon={faCubes} className="fa-icon" />
      </div>
      <div className={`sidebar-btn ${activePanel === 'loadShapefiles' ? 'active' : ''}`} onClick={() => togglePanel('loadShapefiles')}>
        <FontAwesomeIcon icon={faFileUpload} className="fa-icon" />
      </div>
    </div>
  );
};

export default Sidebar;
