import React from 'react';
import './Panel.css';

const LoadShapefilesPanel = () => {
  return (
    <div className="panel">
      <h2>Load Shapefiles</h2>
      <p>Load Shapefiles Content</p>
    </div>
  );
};

export default LoadShapefilesPanel;
