import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './HomePage';
import GeospatialApp from './GeospatialApp';
import ThreeDGeospatialApp from './ThreeDGeospatialApp'; // Import your 3D Geospatial Component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/geospatial" element={<GeospatialApp />} />
        <Route path="/3dgeospatial" element={<ThreeDGeospatialApp />} /> 
      </Routes>
    </Router>
  );
}

export default App;
