import React, { useState } from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './GeospatialApp.css';
import Sidebar from './Sidebar';
import LayerListPanel from './LayerListPanel';
import FindDirectionPanel from './FindDirectionPanel';
import Add3DDataPanel from './Add3DDataPanel';
import LoadShapefilesPanel from './LoadShapefilesPanel';
import SearchComponent from './SearchComponent';


const GeospatialApp = () => {
  const [activePanel, setActivePanel] = useState(null);
  const [layers, setLayers] = useState([]);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false); // Chatbot state

  const handlePanelOpen = (panel) => {
    setActivePanel(panel);
  };

  const handleAddLayer = (layer, layerName) => {
    setLayers(prevLayers => {
      if (layer) {
        return [...prevLayers, layer];
      } else {
        return prevLayers.filter(l => l.name !== layerName);
      }
    });
  };

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };

  return (
    <div className="geospatial-app">
      <Sidebar onPanelOpen={handlePanelOpen} />
      <MapContainer
        center={[1.3521, 103.8198]}
        zoom={16}
        className="map-container"
        zoomControl={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          id="base-layer"
        />
        <ZoomControl position="bottomleft" />
        <SearchComponent />
        {activePanel === 'layerList' && <LayerListPanel layers={layers} />}
        {activePanel === 'findDirection' && <FindDirectionPanel />}
        {activePanel === 'add3DData' && <Add3DDataPanel onAddLayer={handleAddLayer} />}
        {activePanel === 'loadShapefiles' && <LoadShapefilesPanel />}
      </MapContainer>
      
    </div>
  );
};

export default GeospatialApp;
