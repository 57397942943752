import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import './Chatbot.css';

const suggestions = [
  "Upload Data",
  "Update Data",
  "Generate Plan",
  "Generate Data Update Report",
  "Generate WebMap",
  "Show Updated Data on Map",
  "Publish Map Service"
];

const Chatbot = ({ isOpen, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [chatbotResponse, setChatbotResponse] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      const filtered = suggestions.filter(suggestion =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setFilteredSuggestions([]);
    setChatbotResponse(`You selected: ${suggestion}`);
  };

  const handleSubmit = () => {
    setChatbotResponse(`You submitted: ${inputValue}`);
    setInputValue('');
    setFilteredSuggestions([]);
  };

  if (!isOpen) return null;

  return (
    <div className="chatbot-window">
      <div className="chatbot-header">
        <h3>Location Intelligence</h3>
        <button onClick={onClose}>
          <FaTimes />
        </button>
      </div>
      <div className="chatbot-body">
        {chatbotResponse && <div className="chatbot-response">{chatbotResponse}</div>}
        {filteredSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {filteredSuggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="chatbot-footer">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Type here..."
          className="chatbot-input"
        />
        <button onClick={handleSubmit} className="send-button">
          Send
        </button>
      </div>
    </div>
  );
};

export default Chatbot;
