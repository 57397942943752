import React, { useState } from 'react';
import './Panel.css';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const buildingData = [
  {
    name: "Large Building",
    coordinates: [
      [1.3521, 103.8198], [1.3521, 103.8202], 
      [1.3518, 103.8202], [1.3518, 103.8198], 
      [1.3521, 103.8198]
    ],
  }
];

const roadData = [
  {
    name: "Road 1",
    coordinates: [[1.3521, 103.8198], [1.3523, 103.8199], [1.3522, 103.8200]],
  },
  {
    name: "Road 2",
    coordinates: [[1.3531, 103.8188], [1.3533, 103.8189], [1.3532, 103.8190]],
  }
];

const Add3DDataPanel = ({ onAddLayer }) => {
  const map = useMap();
  const [showBuildings, setShowBuildings] = useState(false);
  const [showRoads, setShowRoads] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (accordion) => {
    setActiveAccordion(activeAccordion === accordion ? null : accordion);
  };

  const toggleBuildingsLayer = () => {
    if (showBuildings) {
      map.eachLayer((layer) => {
        if (layer.options && layer.options.className === 'building') {
          map.removeLayer(layer);
        }
      });
      onAddLayer(null, 'Buildings');
    } else {
      buildingData.forEach(building => {
        L.polygon(building.coordinates, {
          color: '#007bff',
          weight: 1,
          fillOpacity: 0.6,
          className: 'building',
        }).addTo(map);
      });
      onAddLayer({ name: 'Buildings', color: '#007bff' }, 'Buildings');
    }
    setShowBuildings(!showBuildings);
  };

  const toggleRoadsLayer = () => {
    if (showRoads) {
      map.eachLayer((layer) => {
        if (layer.options && layer.options.className === 'road') {
          map.removeLayer(layer);
        }
      });
      onAddLayer(null, 'Roads');
    } else {
      roadData.forEach(road => {
        L.polyline(road.coordinates, {
          color: '#ff6600',
          weight: 3,
          opacity: 0.6,
          className: 'road',
        }).addTo(map);
      });
      onAddLayer({ name: 'Roads', color: '#ff6600' }, 'Roads');
    }
    setShowRoads(!showRoads);
  };

  return (
    <div className="panel open">
      <h2 className="panel-title">2D Data</h2>
      <div className="accordion">
        <div className="accordion-item">
          <div
            className="accordion-title"
            onClick={() => toggleAccordion('buildings')}
          >
            <h3>Add 2D Buildings</h3>
          </div>
          {activeAccordion === 'buildings' && (
            <div className="accordion-content">
              <div className="icon-container" onClick={toggleBuildingsLayer}>
                <span>{showBuildings ? 'Remove 2D Buildings' : 'Add 2D Buildings'}</span>
              </div>
            </div>
          )}
        </div>
        <div className="accordion-item">
          <div
            className="accordion-title"
            onClick={() => toggleAccordion('roads')}
          >
            <h3>Add 2D Roads</h3>
          </div>
          {activeAccordion === 'roads' && (
            <div className="accordion-content">
              <div className="icon-container" onClick={toggleRoadsLayer}>
                <span>{showRoads ? 'Remove 2D Roads' : 'Add 2D Roads'}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Add3DDataPanel;
